.preview-box::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.preview-box {
  .cardHeader {
    text-align: left;
    font-size: 1rem;
  }
  .cardWrapper {
    flex-shrink: 0;
    height: unset;
  }

  .cardLink {
    display: none;
  }

  .MuiButton-root {
    pointer-events: none;
  }

  ul {
    list-style: none;
  }

  ol {
    text-align: left;
  }

}
