.signup-img {
  width: 100%;
  height: 500px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/faq_signup.jfif");
  background-repeat: no-repeat;
  background-size: contain;
}

.verify-img {
  width: 100%;
  height: 500px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/faq_verify.jfif");
  background-repeat: no-repeat;
  background-size: contain;
}

.product-img {
  width: 100%;
  height: 500px;
  margin: 20px auto;
  max-width: 250px;
  background: url("../../assets/images/faq_product.jfif");
  background-repeat: no-repeat;
  background-size: contain;
}

.product2-img {
  width: 100%;
  height: 500px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/faq_product2.jfif");
  background-repeat: no-repeat;
  background-size: contain;
}

.project-img {
  width: 100%;
  height: 500px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/faq_project.jfif");
  background-repeat: no-repeat;
  background-size: contain;
}

.link-img {
  width: 100%;
  height: 900px;
  margin: 20px auto;
  max-width: 500px;
  background: url("../../assets/images/faq_link.jpg");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center 0;
}

.settings-img {
  width: 100%;
  height: 600px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/faq_settings.jfif");
  background-repeat: no-repeat;
  background-size: contain;
}

.bot-img {
  width: 100%;
  height: 300px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/faq_bot.jfif");
  background-repeat: no-repeat;
  background-size: contain;
}

.create-tg-bot {
  width: 100%;
  height: 300px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/create_tg_bot.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.setinline-tg-bot {
  width: 100%;
  height: 200px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/setinline_tg_bot.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.links-list-bot {
  width: 100%;
  height: 200px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/links_list_bot.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.link-bot {
  width: 100%;
  height: 200px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/link_bot.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.products-list-bot {
  width: 100%;
  height: 200px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/products_list_bot.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.product-bot {
  width: 100%;
  height: 400px;
  margin: 20px auto;
  max-width: 350px;
  background: url("../../assets/images/product_bot.png");
  background-repeat: no-repeat;
  background-size: contain;
}
