.VerifiedPopoverContent {
  padding: 26px 16px 8px;
  max-width: 320px;
  text-align: center;
}

.Link {
  display: block;
  padding: 16px;
  padding-top: 8px;
  border-top: 1px solid #d9d9d9;

  color: #3d55ee;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
}
