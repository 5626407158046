.PackageDetails {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.descriptionWrapper {
  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
