.wrapper {
  border: 1px solid #e0e0e0; /* серый цвет обводки */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* тень под элементом */
  border-radius: 10px;
  padding: 16px; /* отступы внутри элемента */
  margin: 16px 0; /* отступы снаружи элемента */
  width: 100%;
}

.header {
  font-size: 26px;
  font-weight: 700;
}

.inputWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 колонки с равным разделением
  grid-gap: 16px; // Можно отрегулировать, если нужно другое расстояние

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Все элементы будут в одну колонку на мобильных */
  }
}

.Input {
  max-width: 300px;
}

.InputAddress {
  align-self: center;
}
