.currentPrice {
  font-size: 20px;
}

.previousPrice {
  font-size: 22px;
  color: #828282;
  text-decoration: line-through;
}

