.cart-main__container {
  background-color: #f6f8fa;
  height: 100%;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  @media (max-width: 810px) {
    padding: 0;
  }
}

.cart-main__wrapper {
  margin-left: 20px;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 32px;
  @media (max-width: 810px) {
    margin-left: 0;
  }
}

.cart-main__header {
  font-weight: 600;
  margin-bottom: 24px;
  margin-left: 0;
  font-size: 32px;
  @media (max-width: 810px) {
    margin-left: 24px;
  }
}

.cart-main {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  b {
    font-size: 25px;
  }

  .cart-main__products-list {
    width: 100%;
    min-height: 200px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 35px;
    background-color: white;
    align-items: flex-start;
    position: relative;
    @media (max-width: 650px) {
      padding: 0;
      padding-top: 16px;
    }
  }

  .cart-main__products-list-empty {
    align-self: center;
    svg {
      width: 400px;
    }
  }

  .cart-main__order-summary-wrapper {
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    height: 200px;
    align-items: flex-start;

    .cart-main__sum {
      display: flex;
      align-items: center;

      p {
        font-weight: 700;
        font-size: 20px;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        color: #223bdd;
      }
    }
  }
}
