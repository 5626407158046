.imgWrapper {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  overflow: hidden;

  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 250px;
  font-weight: 500;
  font-size: 18px;
}

.optionName {
  font-weight: 500;
  font-size: 15px;
  margin-right: 5px;
}

.optionValue {
  font-size: 15px;
}
