.analytics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.svg-wrapper-projects {
  width: 65px;
  height: 65px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;

  svg {
    fill: #fff;
    width: 30px;
    height: 30px;
  }
}

.box-analytics-wrapper {
  margin-top: 50px;
  width: 300px;
  background: #fff;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.analytic-value {
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 10px 0;
  padding: 0;
}

.analytic-name {
  color: $descriptionText;
}

@media (max-width: 1300px) {
  .box-analytics-wrapper {
    width: 30%;
  }

  .analytic-value {
    font-size: 22px;
  }
}

@media (max-width: 900px) {
  .analytics {
    flex-direction: column;
    align-items: center;
  }

  .svg-wrapper-projects {
    width: 80px;
    height: 80px;
    svg {
      width: 60px;
      height: 60px;
    }
  }

  .box-analytics-wrapper {
    margin-top: 30px;
    width: 80%;
    grid-template-columns: 80px 1fr;
  }
}

@media (max-width: 600px) {
  .box-analytics-wrapper {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .svg-wrapper-projects {
    width: 65px;
    height: 65px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}
