.cart-main__products-wrapper {
  display: grid;
  grid-template-columns: 2fr 0.7fr;
  grid-template-rows: 1fr 50px;
  column-gap: 30px;
}

.cart-stepper-wrapper {
  padding: 0;
}

.cart-main_count-products-wrapper {
  color: rgb(99, 115, 129);
  margin-left: 12px;
  width: 40%;
  width: 36px;
  height: 36px;
  background-color: #e2e8ee;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}

.cart-main__name {
  margin: 0;
  display: flex;
  font-size: 26px;
  margin-bottom: 18px;
  margin-left: 24px;
  font-weight: 600;
}

.cart-main__name--show-all-mobile {
  @media (max-width: 450px) {
    margin-top: 40px;
  }
}

.cart-price {
  display: inline-flex;
  align-items: center;
}

.cart-main_product-header {
  background: rgb(244, 246, 248);
}

.cart-main__delete-icon {
  cursor: pointer;
  border-radius: 0;
  background: inherit;
  border: none;
  height: 100%;
  svg {
    width: 50px;
  }
  @media (max-width: 650px) {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border: 2px solid #d8dbef;
    padding: 2px;
    svg {
      width: auto;
    }
  }
}

.cart-main__delete-icon:hover {
  svg path{
    fill: #F34343;
  }
}

.cart-main__product-img {
  width: 52px;
  height: 52px;
}

.cart-main__product-name {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    border-radius: 5px;
  }

  b {
    margin-left: 15px;
    font-size: 18px;
  }
}

.cart-main__product-field {
  text-align: center;
  font-size: 16px;
}

.cart-main__product-field--summary-price {
  color: #223bdd;
  font-weight: 700;
}

.cart-main__product-field--price {
  font-weight: 500;
  color: #626D7A;
}

.cart-main__product-header-field {
  text-align: center;
  font-size: 16px;
}

.cart-main__product-header-field:first-child {
  text-align: start;
}

.cart-main__change-count {
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 5px;
  min-width: 130px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background-color: #f4f5fd;

  span {
    display: block;
  }

  button:disabled {
    opacity: 0.3;
  }
}

.cart-main__back {
  display: block;
}

@media (max-width: 1400px) {
  .cart-main__products-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 350px;
    gap: 30px;
  }

  .cart-main__order-summary-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    width: 50%;
  }
}

@media (max-width: 900px) {
  .cart-main__order-summary-wrapper {
    width: 100%;
  }
}

.cart-main__product-name-text {
  margin: 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}
