.cart-main_mobile-list {
  width: 100%;

  .cart-main__product-img-wrapper {
    padding: 8px;
    box-sizing: border-box;
    img {
      min-width: 96px;
      height: 96px;
      border-radius: 20px;
    }
  }

  .cart-main_mobile-card {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    p {
      line-height: 19px;
    }

    h3 {
      font-size: 16px;
      line-height: 17px;
      margin: 0;
    }

    &:last-child {
      .cart-main__product-description {
        border-bottom: none;
      }
    }
  }

  .cart-main__product-description {
    padding: 8px;
    flex-grow: 1;
    border-bottom: 1px solid #d8dbef;
  }

  .cart-main__product-name-mobile {
    padding-right: 40px;
    font-weight: 700;
    font-size: 18px;
  }

  .cart-main__product-options-mobile {
    padding-right: 40px;
  }
}

.cart-main__mobile-product-price {
  p {
    font-weight: 700;
    color: #626d7a;
  }
}

.cart-main__product-name-mobile-text {
  font-size: 16px;
  margin: 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cart-main .alert-request {
  width: 260px;

  @media (max-width: 500px) {
    right: 0;
    top: 10px;
    width: 100%;
  }
}

.cart-main__back-link {
  position: fixed;
  top: 100px;
  right: 50px;
  font-size: 14px;
  width: 260px;
  height: 50px;
  justify-content: flex-start;
  border-radius: 20px;

  svg {
    width: 23px;
    height: 23px;
    margin-right: 12px;
  }

  @media (max-width: 500px) {
    right: 0;
    top: 70px;
    width: 100%;
  }

}
