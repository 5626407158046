.wrapper {
  border: 1px solid #e0e0e0; /* серый цвет обводки */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* тень под элементом */
  border-radius: 10px;
  padding: 16px; /* отступы внутри элемента */
  margin: 16px 0; /* отступы снаружи элемента */
  width: 100%;
}

.header {
    font-size: 26px;
    font-weight: 700;
}

.totalAmountWrapper {
  font-weight: 700;
  font-size: 22px;

  span {
    margin-left: 5px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}