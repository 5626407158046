.nav-count-cart {
  font-size: 1rem;
  margin-top: 2rem;
  position: sticky;
  align-self: center;
  bottom: 5vh;
  border-radius: 20px;
  height: 42px;
  padding: 8px 16px;
  padding-left: 8px;
  z-index: 1000;
  box-shadow: 0px 8px 6px -6px rgba(34, 59, 221, 0.25), 0px 11px 10px -8px rgba(34, 59, 221, 0.3);

  &:hover {
    background-color: #F4F5FD;
    color: #223BDD
  }

  svg {
    height: 18px;
    width: 18px;
  }
}
