.wrapper {
  background-color: #d1e9fc;
  border-radius: 10px;
  padding: 15px;
}

.title {
  color: #04297a;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.text {
  color: #04297a;
  font-size: 14px;
  font-weight: 400;
}

.accent {
  margin-top: 10px;
  padding: 4px 9px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #04297a;
  font-size: 14px;
  font-weight: 600;
  width: max-content;

  &Completed {
    color: rgba($color: #04297a, $alpha: 0.4);
  }
}
