.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 15px;

  span {
    margin-right: 10px;
  }
}

.wrapper {
  margin: 30px 0;
}
