@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/Rubik-Regular.woff2") format("woff2"),
    url("../assets/fonts/Rubik-Regular.woff") format("woff");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../assets/fonts/Rubik-Medium.woff2") format("woff2"),
    url("../assets/fonts/Rubik-Medium.woff") format("woff");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../assets/fonts/Rubik-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Rubik-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../assets/fonts/Rubik-Bold.woff2") format("woff2"),
    url("../assets/fonts/Rubik-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../assets/fonts/Gilroy-Bold.woff2") format("woff2"),
  url("../assets/fonts/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../assets/fonts/Gilroy-Medium.woff2") format("woff2"),
  url("../assets/fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/Gilroy-Regular.woff2") format("woff2"),
  url("../assets/fonts/Gilroy-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../assets/fonts/Roboto-Bold.woff2") format("woff2")
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../assets/fonts/Roboto-Medium.woff2") format("woff2")
}
