.header {
    color: #212B36;
    font-size: 28px;
    font-weight: 600;
}

.wrapper {
    max-width: 922px;
    padding: 0 25px;
    
    @media (max-width: 767px) {
        padding: 0 10px;
    }
}