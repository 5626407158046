.productCardReviewsWrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media (max-width: 1024px) {
    margin: 3px 0;
  }
}

.productCardReviewsRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;

  @media (max-width: 1024px) {
    margin-right: 0;
  }

  span {
    font-size: 16px;
    opacity: 40%;
    color: rgba($color: #ffffff, $alpha: 0.6);

    @media (max-width: 1024px) {
      font-size: 13px;
    }
  }

  &White {
    span {
      color: rgba($color: #000000, $alpha: 0.6);
    }
  }
}

.productCardReviewsCount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 40%;

  span {
    margin-left: 5px;
    font-size: 16px;
    color: rgba($color: #ffffff, $alpha: 0.6);

    @media (max-width: 1024px) {
      font-size: 13px;
      margin-left: 0;
    }
  }

  &White {
    span {
      color: rgba($color: #000000, $alpha: 0.6);
    }
  }
}

.productCardReviewsEmptyCount {
  display: flex;
  align-items: center;
  opacity: 40%;

  span {
    margin-left: 5px;
    font-size: 16px;

    color: rgba($color: #ffffff, $alpha: 0.6);

    @media (max-width: 1024px) {
      font-size: 13px;
      margin-left: 0;
    }
  }

  &White {
    span {
      color: rgba($color: #000000, $alpha: 0.6);
    }
  }
}
