.citySearchWrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 0;
    margin-bottom: 15px;

    .acceptButton {
      margin-top: 0;
    }
  }
}

.autocomplete {
  width: 100%;
}

.acceptButton {
  height: 100%;
  padding: 15px;
  margin-top: 20px;

  @media (max-width: 800px) {
    width: 100%;
  }
}
