.select {
    color: white;
    border-radius: 20px;
    background-color: transparent;

    &White {
        color: #000000;
    
        .MuiSelect-icon {
          color: #000000;
        }
      }
  
    .MuiSelect-select {
      padding-right: 26px;
    }
  
    .MuiSelect-icon {
      color: white;
    }
  }
  