.preview-box::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-link {
  display: flex;
  flex-direction: column;
}

@media (max-width: 420px) {
  .custom-link__logo-without-banner {
    margin-top: 60px;
  }
}

.custom-link__customer-profile {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1000;

  @media (max-width: 600px) {
    right: 8px;
    top: 8px;
    button {
      padding: 0;

      span {
        margin: 0;
      }
    }
    .custom-link__customer-text {
      display: none;
    }
  }
}
