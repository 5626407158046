.custom-fields-list {
  margin-bottom: 16px;
  display: grid;
  row-gap: 16px;
  overflow: hidden;
  padding: 5px;
}

.custom-fields-list__item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 20px;
  align-items: start;
}

.custom-fields__value-item {
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
}
