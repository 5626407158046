.wrapper {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.itemWrapper {
  @media (max-width: 768px) {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header {
  font-size: 18px;
  font-weight: 500;
  color: #505050;
}

.value {
  font-weight: 600;
  font-size: 20px;
}
