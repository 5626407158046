.currentPrice {
  font-size: 20px;
}

.previousPrice {
  font-size: 22px;
  color: #828282;
  text-decoration: line-through;
}

.cardListDisplayWrapper {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  border-bottom: 1px solid #b8bab8;
  background-color: #f9fafb;
  margin-bottom: 30px;
  border-radius: 16px;
  box-shadow: rgb(145 158 171 / 45%) 0px 0px 2px 0px,
    rgb(145 158 171 / 40%) 0px 12px 24px -4px;
}

.cardHeaderListDisplayWrapper {
  font-size: 28px;
  margin: 0;
}

.cardTags {
  overflow: scroll;
}

.cardPrice {
  color: #223BDD;
  font-weight: 500;
  font-size: 1rem;
}

.cardPreviousPrice {
  font-weight: 500;
  font-size: 1rem;
}

.cardListWrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 225px;
  width: 100%;

  padding: 10px 0;
  border-top: 1px solid rgba(#FFFFFF, 0.2);

  &White {
    border-top: 1px solid rgba(#000000, 0.2);
  }

  @media (max-width: 1024px) {
    height: 120px;
    padding: 0;
    border: none;
    margin-bottom: 10px;
  }
}

.cardWrapper {
  display: flex;
  width: 100%;
  max-width: 314px;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 100%;
  }
}

.imgSliderWrapper {
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  @media (max-width: 1024px) {
    border-radius: 10px;
  }

  &List {
    max-width: 225px;

    @media (max-width: 1024px) {
      max-width: 120px;
      height: 120px;
    }
  }
}

.imgSliderMask {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Здесь твои стили для маски, например, фон, размеры и т.д. */
  color: #fff; /* Цвет текста */
  font-size: 50px; /* Размер текста */
  font-weight: 700;
  background-color: rgba($color: #000000, $alpha: 0.4);
}

.cardTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Количество отображаемых строк */
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; 
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;

  &White {
    color: #000000;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
    height: 38px;
    margin-right: 10px;
  }
}

.cardListContent {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-left: 20px;

  .cardTitle {
    height: auto;
    max-width: 400px;
  }
}

.cardListActions {
  margin-top: auto;
  margin-left: auto;

  @media (max-width: 1024px) {
    margin: 0;
    margin-top: auto;
  }
}

.moreTextWrapper {
  display: none;

  @media (max-width: 1024px) {
    display: block;
    font-size: 10px;
    width: auto;
    align-self: center;
    margin-top: 10px;
    color: #ffffff;
  }

  // @media (max-width: 1024px) {
  //   display: none;
  // }
}