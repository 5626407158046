.mobileSearch {
    max-width: 100%;
}

.mobileSearchToolIcon {
    display: flex;
    align-items: center;
    padding: 5px 10px;

    border-left: 1px solid rgba(#FFFFFF, 0.4);

    &White {
        border-left: 1px solid rgba(#000000, 0.4);
    }
}