.Packages {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.PackagesBtn {
  @media screen and (width > 768px) {
    max-width: max-content;
  }
}

.PackageWrapper {
  position: relative;

  button {
    transform: translate(50%, -50%);
  }
}
