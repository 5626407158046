.name {
  font-weight: 500;
  font-size: 15px;
}

.wrapper {
  a {
    color: rgb(145, 158, 171);
    text-decoration: none;
  }
}
