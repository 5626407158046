.linkItem {
  display: grid;
  grid-template-columns: 1fr 2fr 30px;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 15px 35px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
}

.linkItem__interface {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.linkItemAnalytic {
  display: flex;
  justify-content: space-around;
  width: 80px;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
}

.link-item-btn {
  font: inherit;
  display: inline-flex;
  align-items: center;
  text-align: left;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
}

.link-item-btn__icon {
  width: 20px;
  height: 20px;
  color: black;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .linkItem__title-wrapper {
    grid-area: title;
  }

  .linkItem__interface {
    grid-area: interface;
  }

  .linkItem__action-popup {
    grid-area: action;
  }

  .linkItem__interface {
    justify-content: space-between;
  }

  .linkItem {
    grid-template-areas:
      'title title action'
      'interface interface interface';
  }
}

@media (max-width: 500px) {
  .linkItemEditButton {
    align-self: start;
    padding: 0;
  }

  .linkItemQRandCopyBtns {
    width: 100px;
    display: flex;
    justify-content: space-between;

    .link-item-btn {

      .link-item-btn__icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  .linkItemAnalytic {
    align-self: start;
  }

  .linkItemName {
    margin-bottom: 5px;
  }

  .linkItem {
    height: auto;
    padding: 10px;
  }
}
