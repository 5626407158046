.searchBar {
  display: flex;
  align-items: center;
  border-radius: 13px;
  padding: 13px 15px;
  max-width: 467px;
  width: 100%;

  background-color: rgba($color: #ffffff, $alpha: 0.2);

  &White {
    background-color: rgba($color: #000000, $alpha: 0.08);
  }

  @media (max-width: 1024px) {
    padding: 6px;
    border-radius: 10px;
  }
}

.searchIcon {
  background: none;
  border: none;
  color: white; // цвет иконки, настроить в соответствии с изображением
  cursor: pointer;
  font-size: 24px; // размер иконки, настроить по необходимости
  margin-right: 8px; // отступ от иконки до инпута, настроить по необходимости
}

.searchInput {
  flex: 1;
  background: none;
  border: none;
  color: white;
  outline: none;
  font-size: 16px;

  &::placeholder {
    color: rgba($color: #ffffff, $alpha: 0.5);
  }

  &White {
    color: #000000;

    &::placeholder {
      color: rgba($color: #000000, $alpha: 0.5);
    }
  }
}
