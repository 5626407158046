.content {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px;
  min-width: 300px;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: scroll;
  max-height: 80vh;
  outline: none;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  padding: 10px;
  position: absolute;
  top: 8px; /* Отрегулируй эти значения в соответствии с дизайном */
  right: 0px;
}
