//Viewports
$tablet-width: 768px;
$desktop-width: 1440px;
$mobile-width-only: 767px;
$manrope: 'Manrope', 'Roboto';

//Colors
$footer-border-color: #b8b6b0;
$document-link-color: #328ffa;

* {
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
}

:root {
  --text-primary: #232629;
  --text-secondary: #626D7A;
  --text-tertiary: #96A0AB;
  --accent-blue: #223BDD;
  --accent-blue-medium: #4E62E4;
  --positive: #1EAE54;
  --positive-light: #22C45F;
  --positive-hover: #3BDD78;

  --background-primary-alpha-20: rgba(255, 255, 255, 0.20);
  --background-secondary-alpha-90: rgba(244, 245, 250, 0.90);
  --background-secondary: #F4F5FA;
  --background-tertiary: #E5E7F2;
  --background-accent-primary-tint: #E9EBFC;
  --background-accent-yellow: #F7E400;
  --warning: #FFB925;

  --stroke-primary: #CACFE7;

  --negative: #F9303F;

  --font-family: 'Manrope', 'Arial', sans-serif;
}

.mainpage {
  background-color: #F4F5FA;
  padding: 0;
  font-family: var(--font-family);
  @media (min-width: $tablet-width) {
    padding: 0 16px;
  }

  @media (min-width: $desktop-width) {
    padding: 0 20px;
  }
}

.container {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;

  @media (min-width: $tablet-width) {
    padding: 0 50px;
    width: 100%;
  }

  @media (min-width: $desktop-width) {
    width: 100%;
    padding: 0;
    max-width: 1416px;
  }
}

.mainpage__color-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  border-radius: 16px;


  @media (min-width: $desktop-width) {
    margin: 72px 0;
  }
}

.mainpage__color-section-wrapper {
  padding: 0 16px;
  @media (min-width: $tablet-width) {
    padding: 0 50px;
    width: 100%;
    margin: 0;
  }
  @media (min-width: $desktop-width) {
    max-width: 1416px;
    width: 100%;
    padding: 0 20px;

  }
}

.mainpage__color-section--blue {
  background-color: var(--accent-blue);
}

.mainpage__color-section--green {
  background-color: var(--positive);
}

.mainpage__button {
  padding: 12px 24px;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  transition: background-color ease-in 200ms;
  font-family: inherit;

  @media screen and (min-width: $desktop-width){
    font-size: 16px;
    line-height: 24px;
  }
}

.mainpage__button--large {
  height: 48px;

  @media screen and (min-width: $desktop-width){
    font-size: 18px;
    line-height: 28px;
    height: 56px;
  }
}

.mainpage__button--medium {
  height: 48px;
  font-size: 16px;
  line-height: 24px;
}

.mainpage__button--blue,
button.mainpage__button--blue,
a.mainpage__button--blue {
  background-color: var(--accent-blue);
  color: white;
  &:hover {
    background-color: var(--accent-blue-medium);
  }
}

.mainpage__button--white {
  background-color: white;
  color: var(--accent-blue);
}

.mainpage__button--yellow {
  background: var(--background-accent-yellow);
  color: var(--text-primary);

  &:hover {
    background: var(--warning);
  }
}

.onlyDesktop {
  display: none;
  @media screen and (min-width: $desktop-width){
    display: block;
  }
}

.promo {
  background-image: url("../../assets/images/background-promo.svg");
  background-position: center 180px;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 600px;
  text-align: center;
  margin-bottom: 30px;
  padding-top: 40px;
}

.main-page__text-header {
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: $tablet-width) {
    font-size: 56px;
    line-height: 70px;
    text-align: left;
  }

  &--center {
    @media (min-width: $tablet-width) {
      text-align: center;
    }
  }
}

.main-page__text {
  font-size: 20px;
  font-weight: 400;
  text-align: center;

  @media (min-width: $tablet-width) {
    text-align: left;
  }

  &--center {
    @media (min-width: $tablet-width) {
      text-align: center;
    }
  }
}

.section__title {
  font-size: 32px;
  margin: 0;
  padding-top: 32px;
  padding-bottom: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.874px;

  @media (min-width: $desktop-width) {
    padding-top: 72px;
    padding-bottom: 72px;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.92px;
  }
}

.section__title--highlight {
  color: #223BDD;
}

.promo__image {
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  @media (min-width: $tablet-width) {
    margin-top: 300px;
  }

}

.advanteges {
  margin-top: 90px;
}

.advanteges__list {
  list-style: none;
}

.advanteges__list-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 40px;

  @media (min-width: $tablet-width) {
    gap: 40px;
    grid-template-columns: 50% 50%;
    min-height: 600px;
    margin-bottom: 80px;
    &:last-child {
      min-height: 450px;
    }
  }
}

.advanteges__list-item-imege {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 60%;
  background-position: top center;
  @media (min-width: $tablet-width) {
    padding-top: 0;
    width: auto;
    height: auto;
  }
}

.advanteges__list-item-imege--anywhere {
  background-image: url("../../assets/images/advantage-anywhere.svg");
  order: 1;
  @media (min-width: $tablet-width) {
    background-position: top right;
    order: 0;
  }
}

.advanteges__list-item-imege--place {
  background-image: url("../../assets/images/advatnege-one-place.png");
  @media (min-width: $tablet-width) {
    background-position: top left;
  }
}

.advanteges__list-item-imege--safe {
  background-image: url("../../assets/images/advatnege-safe.svg");
  order: 1;
  background-size: 60%;
  @media (min-width: $tablet-width) {
    background-position: top left;
    background-size: 85%;
    order: 0;
  }
}

.tariff {
  text-align: center;
  margin-bottom: 90px;
}

.tariff-list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tariff-cart {
  width: 250px;
  min-height: 300px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.tariff-cart__description-container {
  border-bottom: 1px solid $footer-border-color;
  padding: 20px;
}

.tariff-cart__title {
  display: inline-block;
  background-color: $document-link-color;
  color: white;
  font-size: 15px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  margin: 0;
  margin-bottom: 10px;
}

.tariff-cart__cost {
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}

.tariff-cart__description {
  font-size: 12px;
  margin: 0;
}

.tariff-cart__list-property {
  padding: 10px;
  font-size: 15px;
  list-style: none;
  text-align: left;
}

.tariff-cart__link {
  margin-top: auto;
  width: 100%;
  padding: 20px;
}

.community {
  text-align: center;
}

.community__text {
  text-align: center;
}

.community__video {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 100%;

  @media (min-width: $tablet-width) {
    width: 420px;
    height: 315px;
  }
}

.community__link {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.documets-list {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  // justify-content: space-between;
}

.documets-list__link {
  color: $document-link-color;
}

.documets-list__item:not(:last-child) {
  padding-right: 24px;
}

.main__footer {
  margin-top: 30px;
}

.footer__links {
  border-top: 1px solid $footer-border-color;
  border-bottom: 1px solid $footer-border-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.social-list {
  list-style: none;
  display: flex;

}

.social-list__item {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.social-list__link {
  color: inherit;
  transition: color 0.2s ease;

  &:hover, &:focus {
    color: #223BDD;
  }
}

.faq-section {
  margin-bottom: 20px;

  * {
    font-family: var(--font-family);
  }
}
