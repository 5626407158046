.verification-email__wrapper {
  background: url("./../../assets/images/gradient-bg.svg");
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  padding: 25px;

}

.verification-email {
  margin: 0 auto;
}

.verification-email__content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  min-height: 250px;
  padding: 20px;
  &--sm {
    max-width: 60vh;
    margin: 0 auto;
  }

  margin-bottom: 30px;
}

.verification-email__help-text {
  color: red;
  margin-top: 20px;
  font-size: 12px;
  padding: 0 20px;
}

@media (max-width: 500px) {
  .verification-email__content {
    min-height: 200px;
  }
}

@media (max-width: 380px) {
  .verification-email__content {
    min-height: 150px;
  }
}
