.new-password.new-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;

  .new-password__password {
    background-color: #fff;
    color: #000;
    width: 100%;
  }
}
