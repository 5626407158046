.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    padding: 0 6px;
}

.material-icons {
    display: grid;
    place-items: center;
    // width: min-content;
}