.NewOrderForm {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
}

.NewOrderFormRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  align-items: end;
}
