.ZeroBlock {
  display: flex;
  justify-content: center;
  max-width: 1260px;
  width: 100%;

  div {
    width: 560px;
  }
}
