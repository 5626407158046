.title {
  color: #212b36;
  font-size: 20px;
  font-weight: 600;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}