.wrapper {
    position: relative;
    background-color: #121212;
    height: 100%;
    max-height: 100vh;
    border-radius: 0;
    padding: 0 10px;

    &White {
        background-color: #FFFFFF;
    }
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 15px 5px;

    &Button {
        position: absolute;
        top: 5px;
        left: 0;
        padding: 10px;
    }

    &Title {
        font-weight: 700;
        font-size: 16px;
        width: 100%;
        text-align: center;

        &White {
            color: #121212;
        }
    }
}

.categoryFilter {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
}

.priceFilter {
    width: 100%;
    margin-bottom: 10px;
}

.visibilityTypeWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: auto;

    margin-bottom: 10px;
}

.visibilityTypeItem {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    &White {
        color: #121212;
    }

    span {
        margin-left: 5px;
    }
}