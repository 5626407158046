.products {
  &Cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    row-gap: 1.25rem;

    @media (max-width: 1024px) {
      margin: 10px 0;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &List {
    margin: 0.5rem 0 -1rem;
    align-items: stretch;

    @media (max-width: 1024px) {
      margin: 10px 0;
      padding: 5px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1260px;
}

.cardHeader {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  /* stylelint-disable-next-line */
  -webkit-line-clamp: 2;
  /* stylelint-disable-next-line */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    font-size: 1rem;
  }
}

.cardContent {
  flex: 1;
  justify-content: space-between;

  &__tags {
    max-width: 98vw;
    margin: 0 -8px;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.cardLink {
  position: absolute;
  display: grid;
  place-items: center;
  top: 12px;
  right: 12px;
  z-index: 10;
  width: 34px;
  min-width: unset;
  height: 34px;
  color: #fff;
  transition: 0.2s ease;
  background-color: rgba(#223bdd, 0.2);

  &:hover,
  &:focus {
    color: #223bdd;
  }

  &:focus {
    outline: none;
  }
}

.cartPreview {
  position: sticky;
  bottom: 5vh;
  z-index: 1000;
  text-align: center;
  align-self: center;

  display: flex;
  flex-direction: column;
}

