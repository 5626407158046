.projects-create {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.project-stat-links {
  background: $backgroundProjectLinks;
  border-radius: 5px;
  padding: 5px 10px;

  svg {
    fill: $colorProjectLinks;
  }
}

.project-stat-move {
  background: $backgroundProjectTransition;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 40px;

  svg {
    fill: $colorProjectTransition;
  }
}

@media (max-width: 800px) {
  .projects-create {
    flex-direction: column;
    align-items: flex-start;
  }

  .project-create-button {
    width: 180px;
    margin: 20px 0 0 0;
  }
}
