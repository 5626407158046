.authButton {
    position: absolute;
    right: 20px;
    top: 10vh;
    z-index: 1000;
  
    @media (max-width: 900px) {
      top: 10px;
      right: 8px;
  
      button {
        padding: 0;
  
        span {
          margin: 0;
        }
      }
      &Text {
        display: none;
      }
    }
  }