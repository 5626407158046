.TariffTypeBlock {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ff111199;
  border-radius: 20px;
  align-items: center;
  text-align: center;
}

.info {
  color: #ff111199;
  font-size: 13px;
}

.link {
  font-size: 14px;
  color: #223bdd;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
