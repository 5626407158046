.baseButton {
  display: flex;
  align-items: center;
  height: 46px;
  width: max-content;
  padding: 9px 16px;
  border-radius: 13px;
  background-color: #3d55ee;
  border: transparent;

  &:hover {
    cursor: pointer;
  }

  &List {
    order: 1;

    @media (max-width: 1024px) {
      order: 0;
    }
  }

  @media (max-width: 1024px) {
    height: 37px;
    border-radius: 10px;
    padding: 5px 9px;

    svg {
      transform: scale(0.9);
    }
  }
}

.buttonDisabled {
  margin-top: 10px;
  color: rgba($color: #ffffff, $alpha: 0.3);
  background-color: rgba($color: #ffffff, $alpha: 0.1);

  &White {
    color: rgba($color: #000000, $alpha: 0.3);
    background-color: rgba($color: #000000, $alpha: 0.08);
  }

  @media (max-width: 1024px) {
    margin-top: auto;
  }
}

.buttonDisabledText {
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.oldPrice {
  font-weight: 500;
  font-size: 18px;
  color: rgba($color: #ffffff, $alpha: 0.5);
  text-decoration: line-through;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.currentPrice {
  margin: 0 5px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  @media (max-width: 1024px) {
    margin: 0;
    // flex-direction: column;
  }
}

.moreTextWrapper {
  display: inline-flex;
  margin-left: 10px;
  width: 76px;
  color: rgba($color: #ffffff, $alpha: 0.5);

  &White {
    color: rgba($color: #000000, $alpha: 0.5);
  }

  // @media (max-width: 1024px) {
  //   font-size: 10px;
  //   width: auto;
  //   align-self: center;
  // }

  @media (max-width: 1024px) {
    display: none;
  }
}
