.verification__code-inputs-wrapper {
  width: auto;
  margin-bottom: 20px;
}

.verification__code-inputs {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: auto;
  justify-content: center;
  margin-bottom: 10px;

  input {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    border: 1px solid rgb(229, 231, 235);
    font-size: 35px;
    text-align: center;
    padding: 0;
  }

  input:focus,
  input:focus-visible {
    border-color: rgb(99, 102, 241);
    box-shadow: rgb(99 102 241) 0px 0px 0px 2px;
    outline: none;
  }
}

@media (max-width: 700px) {
  .verification__code-inputs {

    input {
      width: 50px;
      height: 50px;
    }
  }
}

@media (max-width: 500px) {
  .verification__code-inputs {

    input {
      width: 40px;
      height: 40px;
      font-size: 30px;
    }
  }
}

@media (max-width: 380px) {
  .verification__code-inputs {

    input {
      width: 35px;
      height: 35px;
      font-size: 25px;
    }
  }
}
