.cart-main__user-name {
    flex-direction: row;
    align-items: center;
}

@media (max-width: 600px) {
    .cart-main__user-name {
      flex-direction: column;
    }
  }

.cart-main__input-delivery {
  border: none;
}