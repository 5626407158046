
:root {
    --scrollbar: #d8dbef;
    --primary: #223BDD;
  }
  
  ::-webkit-scrollbar {
    width: 1.2rem;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    background-color: var(--scrollbar);
  }
  