.title {
  color: #212b36;
  font-size: 20px;
  font-weight: 600;
}

.counter {
  color: #637381;
  font-size: 20px;
  font-weight: 600;
}

.labelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    margin-top: 50px;
}