.alert-request {
  position: fixed;
  width: 350px;
  right: 50px;
  top: 30px;
  z-index: 10000;
}

@media (max-width: 500px) {
  .alert-request {
    right: 0;
    top: 10px;
    width: 100%;
  }
}
