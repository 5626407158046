.cart-main__show-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 0 15px;
  font-size: 18px;
  margin-bottom: 20px;

  span {
    font-weight: 700;
    font-size: 16px;
    color: #1f1f1f;
    min-width: 150px;
  }

  p {
    margin: 0 0 0 15px;
    font-size: 16px;
    color: #626d7a;
    font-weight: 500;
  }
}
