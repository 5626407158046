.counterWrapper {
  display: flex;
  align-items: center;
  height: 46px;
  width: max-content;
  padding: 9px 16px;
  border-radius: 13px;
  background-color: rgba($color: #ffffff, $alpha: 0.1);

  &White {
    background-color: rgba($color: #000000, $alpha: 0.08);
  }

  @media (max-width: 1024px) {
    height: 37px;
    border-radius: 10px;
    width: 100%;
    justify-content: space-between;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media (max-width: 1024px) {
    margin-top: 0px;
    position: relative;
    flex-direction: column;
  }
}

.moreTextWrapper {
  @media (max-width: 1024px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.moreText {
  display: inline-flex;
  width: 76px;
  margin-left: 10px;
  color: rgba($color: #FFFFFF, $alpha: 0.5);

  &White {
    color: rgba($color: #000000, $alpha: 0.5);
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.currentPrice {
  margin: 0 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;

  &White {
    color: #000000;
  }

  @media (max-width: 1024px) {
    margin: 0 5px;
    font-size: 16px;
  }
}

.button {
  position: relative;
  border: transparent;
  box-sizing: border-box;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    width: 40px;
    height: 40px;
  }

  &:hover {
    transform: scale(1.2);
  }
}
