.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
}

.visibilityTypeWrapper {
    cursor: pointer;
    margin-left: auto;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}