.project-item {
  display: grid;
  grid-template-columns: 1fr 2fr 30px;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #fff;
  padding: 15px 35px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
}

.project-item__analytic {
  display: flex;
  justify-content: space-around;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
}


@media (max-width: 500px) {
  .project-item__edit-button {
    grid-area: edit;
  }

  .project-item__analytic {
    justify-content: start;
    grid-area: analytic;
  }

  .project-item__name {
    margin-bottom: 20px;
    grid-area: names;
  }

  .project-item {
    height: auto;
    grid-template-columns: 1fr 30px;
    grid-template-areas:
      "names  edit"
      "analytic  edit";
  }
}
