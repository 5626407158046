$blue: #0f52fc;
$background: #f7f7f7;
$descriptionText: #858585;
$alert: #fa5a48;

$backgroundProjectLinks: hsla(188, 78%, 85%, 1);
$colorProjectLinks: hsla(188, 60%, 30%, 1);
$backgroundProjectTransition: hsl(211, 100%, 85%);
$colorProjectTransition: hsl(211, 100%, 35%);

p {
  margin: 0;
}

.mobile-only {
  @media (min-width: 900px) {
    display: none;
  }
}


.mainpage {
  background-color: #F4F5FA;
  padding: 0;
  font-family: 'Roboto', 'Tahoma', 'Arial', sans-serif;
  font-weight: 500;
}

.img-control {
  justify-content: center;
  padding: 10px;
  min-width: 50px;
  height: 50px;
  background-color: rgba(22, 28, 36, 0.48);

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}

body {
  padding-right: 0 !important;
}

.mainpage {
  background-color: #F4F5FA;
  padding: 0;
  font-family: 'Roboto', 'Tahoma', 'Arial', sans-serif;
}

.grecaptcha-badge { 
  visibility: hidden;
}

:root {
  --swiper-pagination-bullet-size: 4px;
  --swiper-pagination-bullet-width: 4px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-bottom: 6px;
}
