.wrapper {
  margin-top: 20px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  padding-top: 25px;
  margin: 20px 0;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.taskText {
  margin-bottom: 10px;
  color: #212b36;
  font-size: 14px;
  font-weight: 400;
}
