.headerWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
  }
}

.header {
  font-size: 34px;
  font-weight: 600;
  margin: 0 10px;

  @media (max-width: 768px) {
    margin: 0;
  }
}

.title {
  display: flex;
  align-items: center;
}
