.wrapper {
    max-width: 300px;
    align-self: center;
}

.menuWrapper {
    overflow-y: scroll;
    max-height: 40vh;
}

.item {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    max-width: 300px;
    padding: 3px 5px;

    &:hover {
        background-color: #ededed;
        cursor: pointer;
    }
}

.itemTitle {
    font-size: 15px;
    font-weight: 600;
}